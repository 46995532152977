.red {
  color: $fontColorRed;
}

.heading {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: $padding * 3;
  @include nexaB;

  &:after {
    display: block;
    content: "";
    width: 110px;
    height: 1px;
    background-color: $fontColorRed;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    margin-top: 8px;
  }
}

.hide-small {
  display: none !important;
  @include desktop {
    display: block !important;
  }
}


.hide-big {
  display: block !important;
  @include desktop {
    display: none !important;
  }
}

.toast {
  background-color: #323232;
  color: #fff;
  @include nexaB;
  position: fixed;
  bottom: 10%;
  right: 10%;
  padding: 18px;
  text-transform: uppercase;
  box-shadow: 0 0 8px 1px rgba(0,0,0,.5);
  animation: toast-down 5s ease-in 1s;
  animation-fill-mode: both;
  z-index: -1;
}

@keyframes toast-down {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    z-index: auto;
  }
  8% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  95% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  99% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    z-index: auto;
  }
  100% {
    z-index: -1;
    opacity: 0;
  }
}
