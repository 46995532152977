header.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  @include nexaB;

  .background {
    transition: height .2s ease-in, opacity .2s ease-in;
    height: 0;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-color: $backgroundColorClients;
    opacity: 0;
  }

  &.solid {
    .background {
      opacity: 1;
      height: 60px;

      @include desktop {
        height: 68px;
      }
    }
  }

  .container {
    margin: 0 auto;
    transition: all .2s ease-in;
    padding: $padding;
    flex-flow: wrap;
    align-items: center;

    @include desktop {
      padding: $padding * 2;
      display: flex;
      justify-content: space-between;
    }
  }
  .logo {
    transition: all .2s ease-in;
    width: 61px;
    height: 29px;
    float: left;

    @include desktop {
      width: 122px;
      height: 58px;
    }
  }

  &.shrink {
    .container {

      @include desktop {
        padding: 5px;
      }
    }
    .logo {
      @include desktop {
        width: 70px;
      }
    }
  }

  nav.nav {
    transition: opacity .4s ease-in, top 0s ease .4s,  z-index 0s ease .4s;
    position: fixed;
    left: 0;
    top: -100%;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .9);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -100;
    overflow: hidden;

    ul {
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      height: 60%;
      width: 60%;

      @include desktop {
        flex-direction: row;
        padding: 0 20px;
        width: auto;
        height: auto;
      }

      li {
        display: inline-block;
        transform: translate3d(-50%, 0, 0);
        width: 100%;
        opacity: 0;

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition: all 150ms ease-in 100ms * $i;
          }
        }

        @include desktop {
          opacity: 1;
          transform: translate3d(0, 0, 0) !important;
          width: auto;
          &:hover:after {
            content: '';
            display: block;
            background-color: $fontColorWhite;
            height: 1px;
            width: 52px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
          }
        }
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: $fontColorWhite;
        display: block;
        font-size: 30px;
        padding: 10px;

        @include desktop {
          font-size: inherit;
        }
      }
      @include desktop {
        width: auto;
      }
    }

    @include desktop {
      position: static;
      height: auto;
      width: auto;
      background-color: transparent;
      z-index: auto;
      opacity: 1;
    }
  }
}

.toggle {
  float: right;
  padding: 11px;

  span {
    display: block;
    background-color: $fontColorWhite;
    width: 22px;
    height: 2px;
    transition: all .2s ease-in;
    transform-origin: 0% 50%;

    &:first-child {
      margin-bottom: 6px;
    }
  }

  @include desktop {
    display: none;
  }
}

body.show {
  overflow: hidden;
  nav.nav {
    top: 0;
    opacity: 1;
    z-index: -1;
    transition: opacity .5s ease-in;
    ul li {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .toggle {
    z-index: 200;

    span {
      transform: rotate(-45deg) translate3d(0px, 5px, 0);

      &:first-child {
        transform: rotate(45deg) translate3d(0px, -5px, 0);

      }
    }
  }

}
