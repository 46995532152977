body {
  font-size: 12px;
  color: $fontColorParagraph;
  letter-spacing: 1px;
}
.section {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.container {
  max-width: 950px;
  width: 100%;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: space-between;
}
