@font-face {
  font-family: 'NexaLight';
  src: url('/fonts/Nexa_Free_Light-webfont.eot');
  src: url('/fonts/Nexa_Free_Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Nexa_Free_Light-webfont.woff2') format('woff2'),
  url('/fonts/Nexa_Free_Light-webfont.woff') format('woff'),
  url('/fonts/Nexa_Free_Light-webfont.ttf') format('truetype'),
  url('/fonts/Nexa_Free_Light-webfont.svg#nexa_lightregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NexaBold';
  src: url('/fonts/Nexa_Free_Bold-webfont.eot');
  src: url('/fonts/Nexa_Free_Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Nexa_Free_Bold-webfont.woff2') format('woff2'),
  url('/fonts/Nexa_Free_Bold-webfont.woff') format('woff'),
  url('/fonts/Nexa_Free_Bold-webfont.ttf') format('truetype'),
  url('/fonts/Nexa_Free_Bold-webfont.svg#nexa_boldregular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "myicons";
  src:url("/fonts/myicons.eot");
  src:url("/fonts/myicons.eot?#iefix") format("embedded-opentype"),
  url("/fonts/myicons.woff") format("woff"),
  url("/fonts/myicons.ttf") format("truetype"),
  url("/fonts/myicons.svg#myicons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "myicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="fi-"]:before,
[class*=" fi-"]:before {
  font-family: "myicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-facebook:before {
  content: "\61";
}
.fi-linkedin:before {
  content: "\62";
}
.fi-email:before {
  content: "\63";
}
.fi-twitter:before {
  content: "\64";
}
.fi-stack-overflow:before {
  content: "\65";
}
