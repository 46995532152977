.footer {
  height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $backgroundColorFooter;

  img {
    height: 45px;
    width: 95px;
    margin-bottom: 10px;
  }
  .social {

    display: flex;
    justify-content: space-between;

    a {
      transition: all .2s ease-in-out;
      padding: 5px;
      color: #313131;
      font-size: 24px;
      background-color: rgba(255, 255, 255, .2);
      line-height: 1;
      height: 40px;
      width: 40px;
      margin: 3px 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }

      @include desktop {
        height: 20px;
        width: 20px;
        margin: 5px;
        font-size: 12px;
        padding: 0;

      }
    }
  }
  p {
    font-size: 11px;
  }
}
