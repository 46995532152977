.twitter {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: $fontColorWhite;

  background: #0f0f0f url('/img/v9/image_twitterbg_00.jpg') no-repeat;
  background-size: cover;

  .tweet-text {

    a {
      color: $fontColorRed;
    }
  }

  @include desktop {
    height: 224px;
  }

  .hide-big {
    text-transform: uppercase;
    font-size: 12px;
    @include nexaL;
    margin: 0;
    margin-bottom: 5px;
  }

  .heading {
    @include nexaL;
    line-height: 1;
    margin: 0;
    color: $fontColorWhite;
    font-size: 18px;

    &:after {
      background-color: $fontColorWhite;
      content: none;
    }
    span {
      font-size: 16px;
      padding: 0;

      @include desktop {
        font-size: 20px;
      }
    }

    @include desktop {
      @include nexaB;
      margin-top: 0;
      margin-bottom: $padding;
      &:after {
        content: '';
      }
    }
  }

  .small {
    font-size: 10px;
    opacity: .2;
    margin: 0 5px;
  }
}
