#modal {
  display: flex;
  position: fixed;
  background-color: rgba(0,0,0,.9);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -1;
  justify-content: center;
  align-items: center;
  //opacity: 0;
  transition: all .5s ease-in;
  //transform: translate3d(0, -100%, 0);


  .modal-container {
    width: 90vw;
    max-height: 630px;
    max-width: 562px;
    flex-direction: column;
    background-color: $fontColorWhite;
    position: relative;

    @include desktop {
      font-size: 14px;
    }

    .container-header {
      position: relative;
      height: 70px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include nexaB;

      .title {
        text-transform: uppercase;
      }
      .x {
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        line-height: 1;
        cursor: pointer;

        img {
          height: 15px;
          width: 15px;
          @include desktop {
            width: 20px;
            height: 20px;
          }
        }
        @include desktop {
          right: 24px;

        }

      }
    }

    .container-content {
      position: relative;
      img {
        width: 100%;
        display: block;
      }
    }
  }

  &.show {
    z-index: 1000;
    opacity: 1 !important;
  }

}
