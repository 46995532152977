.team {
  //height: 1500px;
  padding: $padding * 2;

  .heading {
    margin-bottom: $padding * 2;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-flow: column;

    .item {
      width: 100%;
      margin-bottom: $padding;
      text-align: left;

      img {
        width: 25%;
        float: left;
        max-width: 320px;
        margin-bottom: 19px;
        margin-right: $padding * 2;

      }

      h3 {
        font-size: 14px;
        margin: 0;
        text-transform: uppercase;
        color: $fontColorBlack;
        @include nexaB;
      }
      h4 {
        font-size: 11px;
        font-weight: 300;
        margin: 0;
        margin-bottom: 4px;
      }
      a {
        transition: all .3s ease-in;
        font-size: 12px;
        color: #b1b1b1;
        .symbol {
          transition: all .3s ease-in;
          color: #b1b1b1;
          font-size: 26px;
          padding: 0;
          margin-top: -5px;
          &:before {
            content: none;
          }
        }
        &:hover {
          color: #009fe9;
          .symbol {
            color: #009fe9;
          }
        }
      }
    }
  }

  @include desktop {
    height: 498px;

    .heading {
      margin-bottom: $padding * 3;
    }

    .container {
      flex-flow: row;

      .item {
        width: 194px;
        text-align: center;
        margin: 0;
        max-width: 23%;
        img {
          height: auto;
          width: 194px;
          float: none;
          max-width: 100%;

        }

        h4 {
          margin-bottom: 6px;
        }
      }
    }
  }
}
