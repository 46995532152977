.about {
  padding: $padding * 2 $padding;


  @include desktop {
    height: 870px;
  }

  h1, h2 {@include nexaB;}

  .container {
    width: 756px;
    max-width: 95%;

    [class^='item'] {
      width: 25%;
      line-height: 20px;

    }
    .item1 {
      h2, p {
        text-align: left;
      }
    }
    .item2 {
      h2, p {
        text-align: center;
      }
    }
    .item3 {
      h2, p {
        text-align: right;
      }
    }
  }

  .about-us {
    max-width: 95%;

    h2 {
      text-align: left;
    }
    p {
      text-align: justify;
      line-height: 20px;

    }
  }
  .do {
    width: 776px;
    max-width: 98%;

    h2 {
      text-align: left;

      @include desktop {
        text-align: center;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      @include desktop {
        height: 140px;
      }

      li {
        height: 40px;
        width: 100%;
        background-color: $backgroundColorLi;
        color: $fontColorWhite;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        @include nexaL;
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            @if ($i > 3) {
              background-color: $backgroundColorFooter;
              &:hover {
                background-color: #5a5a5a;
              }
              &:active {
                background-color: #222;
              }
            }
          }
        }

        @include desktop {
          width: calc(50% - 20px);
          &:nth-child(3n) {
            margin-bottom: 0;
          }
        }

        &:hover {
          background-color: #de6565;
        }
        &:active {
          background-color: #952b2b;
        }

      }
    }
  }

  hr {
    width: 756px;
    height: 1px;
    background-color: #d6d6d6;
    border: 0;
    margin-top: $padding;
    margin-bottom: $padding;
  }

  h1 {
    margin-bottom: $padding * 3;
  }

  h2 {
    font-size: 14px;
    text-transform: uppercase;
  }
}
