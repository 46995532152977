.clients {
  background-color: $backgroundColorClients;
  color: $fontColorWhite;

  .heading {
    margin-top: 60px;
    &:after {
      background-color: $fontColorWhite;
    }
  }

  .work-grid {
    display: flex;
    max-width: 960px;
    width: 95%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & > div {
      cursor: pointer;
      overflow: hidden;
      width: 45%;

      & div {
        margin: 4%;

        &:after {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
    }
  }

  .clients-grid {
    width: 100%;
    background-color: #e65454;
    margin-top: 80px;

    .clients-flex {
      width: 100%;
      display: flex;
      justify-content: space-around;
      max-width: 920px;
      margin: 0 auto;
      flex-flow: wrap;
      max-width: 80%;
      padding: 50px 0;

      img {
        //width: 14%;
        height: 35px;
        margin: 20px 0;
        width: 60%;

        &:nth-child(4) {
          height: 50px;
        }
      }
    }
  }

  @include desktop {
    .heading {
      margin-top: 120px;
    }
    .work-grid > div {
      width: 30%;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
    .clients-grid .clients-flex {
      img {
        width: auto;
        max-width: 15%;

        &:nth-child(4) {
          height: 35px;
        }

      }
    }
  }
}


































