@mixin nexaL {
  font-family: 'nexaLight';
  letter-spacing: 2px;
}

@mixin nexaB {
  font-family: 'nexaBold';
  letter-spacing: 2px;
}

@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
}



