.home {
  color: $fontColorWhite;
  height: 600px;
  position: relative;
  background: #BA3434 url(/img/v9/image_bannerbg_00.jpg) no-repeat center -200px fixed;
  background-size: cover;

  h1 {
    text-transform: uppercase;
    font-size: 24px;
    border-top: 1px solid $fontColorWhite;
    border-bottom: 1px solid $fontColorWhite;
    padding: $padding;
    max-width: 50%;
    text-align: center;
    line-height: 1.2;
    @include nexaB;

    span {
      display: block;

      @include desktop {
        display: inline;
      }
    }


  }

  div.scroller {
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    @include nexaL;

    img {
      transition: all .3s ease-in;
      display: block;

      text-align: center;
      margin: 20px auto;
      height: 10px;
      width: 20px;

      @include desktop {
        opacity: 0;
      }

    }
  }
  &:hover div.scroller img{
    //animation: scroll 1s ease-in-out 1;
    //animation-fill-mode: both;
    opacity: 1;
    transform: translate3d(0, 10px, 0);
  }
}

@media screen and ( max-height: 830px ){
 .home {
   background:$backgroundColorClients url(/img/v9/image_bannerbg_00.jpg) no-repeat center bottom fixed;
   background-size: cover;

 }
}
