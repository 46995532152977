.contact {
  height: 780px;
  padding: $padding * 2;

  .heading, p {
    margin-bottom: $padding * 2;
    text-align: center;
    line-height: 1.5;
  }

  p a {
    display: inline-block;
    color: #000;
  }

  label.error {
    display: none !important;
  }

  form {
    margin-bottom: 20px;

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }

    ::-webkit-input-placeholder {
      color: $fontColorWhite;
      text-transform: uppercase;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: $fontColorWhite;
      text-transform: uppercase;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $fontColorWhite;
      text-transform: uppercase;
    }

    :-ms-input-placeholder {
      color: $fontColorWhite;
      text-transform: uppercase;
    }

    input, textarea {
      margin-bottom: 20px;
      background-color: $backgroundColorFormInputs;
      border: 0;
      outline: 0;
      box-shadow: none;
      color: $fontColorWhite;
      padding-left: 10px;
    }

    input {
      height: 40px;
      width: 100%;
      margin-right: 20px;
    }
    textarea {
      width: 100%;
      height: 100px;
      padding-top: 10px;
      resize: none;

    }
    button {
      transition: all .2s ease-in;
      height: 40px;
      width: 100%;
      background-color: $fontColorWhite;
      text-transform: uppercase;
      border: 3px solid $backgroundColorFormInputs;
      outline: 0;
      box-shadow: none;
    }
  }

  @include desktop {
    height: 540px;

    .heading, p {
      margin-bottom: $padding * 2;
    }

    form {
      > div {
        height: 165px;
        width: 626px;
        display: flex;
        flex-flow: wrap;
        flex-direction: column;
      }

      input, textarea {
        margin-bottom: 20px;
        background-color: $backgroundColorFormInputs;
        border: 0;
        outline: 0;
        box-shadow: none;
        color: $fontColorWhite;
        padding-left: 20px;

        &:active,
        &:focus {
          background-color: #313131;
        }
      }

      input {
        height: 40px;
        width: 302px;
        margin-right: 20px;

        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
      textarea {
        width: 302px;
        height: 65px;
        padding-top: 10px;

      }
      button {
        height: 40px;
        width: 302px;
        background-color: $fontColorWhite;
        text-transform: uppercase;
        border: 3px solid $backgroundColorFormInputs;
        outline: 0;
        box-shadow: none;

        &:hover {
          background-color: #4a4a4a;
          color: $fontColorWhite;
        }
        &:active {
          background-color: #313131;
          color: $fontColorWhite;
          border: 3px solid #313131;
        }

      }
    }
  }

  .err {
    background-color: #be0000 !important;

    &:active,
    &:focus {
      background-color: #be0000 !important;
    }
  }

  .error-message,
  .success-message {
    color: $fontColorWhite;
    padding: 13px;
    width: 100%;
    font-size: 12px;
    opacity: 0;
    transition: opacity .5s ease-in-out;

    @include desktop {
      width: 562px;
      text-align: center;
    }

    &.show {
      opacity: 1;
    }
  }

  .error-message {
    background-color: #be0000;
  }

  .success-message {
    background-color: #73bf77;
  }
}
