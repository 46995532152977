$fontColorBlack: #3f3f3f;
$fontColorRed: #ba1f1f;
$fontColorWhite: #fff;
$fontColorParagraph: #5e5e5e;
$fontColorGrey: #b1b1b1;

$backgroundColorFooter:  #313131;
$backgroundColorFormInputs: #4a4a4a;
$backgroundColorClients: #e54242;
$backgroundColorLi: #d63e3e;


$padding: 15px;
